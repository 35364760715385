import { FLAGS } from '@/core/flags.js';
import store from '@/store';
import { isMobileScreenWidth } from '@/core/helpers/MobileHelpers.js';

export const TeacherRoutes = [
    {
        path: '/teacher24',
        name: 'host',
        redirect: { name: 'teacher.school' },
        component: () => import('@/teacher/pages/TeacherMainPage.vue'),
        meta: {
            authenticate: true,
            requireTeacherRole: true,
        },
        children: [
            {
                path: 'school',
                name: 'teacher.school',
                component: () => import('@/teacher/pages/SchoolPractice.vue'),
                props: true,
                beforeEnter: (to, from, next) => {
                    if (store.getters['v2/teacher/hideSchoolPracticeWelcome']) {
                        console.debug('SP::beforeEnter welcome screen hidden');
                        next({ name: 'teacher.tbl.setup' });
                    } else if (!to.query.group) {
                        next({
                            ...to,
                            query: {
                                ...to.query,
                                group: store.getters.user.testGroup,
                            },
                        });
                    } else next();
                },
            },
            {
                path: 'live-game-setup',
                name: 'teacher.tbl.setup',
                component: () => import('@/teacher/pages/LiveGameSetup.vue'),
                props: true,
                beforeEnter: (to, from, next) => {
                    // @todo: for now redirect mobile to old UI as we do not
                    // support mobile yet in the new UI.
                    if (isMobileScreenWidth()) {
                        next({ name: 'host.dashboard' });
                    }
                    next();
                },
            },
            {
                path: 'spg-setup',
                name: 'teacher.spg.setup',
                component: () => import('@/teacher/pages/AssignmentSetup.vue'),
                props: true,
                beforeEnter: (to, from, next) => {
                    const canSeeAcademy =
                        store.getters.user?.flags?.[
                            FLAGS.PAID_HOME_ACADEMY_EXPERIMENT
                        ];
                    if (canSeeAcademy) {
                        next({ name: 'academy.assignments' });
                    } else {
                        next();
                    }
                },
            },
            {
                path: 'home',
                name: 'teacher.home',
                component: () =>
                    import('@/teacher/pages/HomePractice/HomePractice.vue'),
                props: true,
            },
            {
                path: 'goal',
                name: 'teacher.goal',
                component: () =>
                    import('@/teacher/pages/WeeklyGoal/WeeklyGoalPage.vue'),
                props: true,
            },
            {
                path: 'individual-practice',
                name: 'teacher.solo',
                component: () =>
                    import(
                        '@/teacher/pages/IndividualPractice/IndividualPractice.vue'
                    ),
                props: true,
            },
            {
                path: 'classes',
                name: 'teacher.classes',
                component: () => import('@/teacher/pages/ClassManager.vue'),
                props: true,
            },
            {
                path: 'class/create',
                name: 'teacher.create.class',
                component: () =>
                    import(
                        '@/teacher/flows/ClassLists/CreateView/CreateClass.vue'
                    ),
                props: true,
            },
            {
                path: 'reports',
                name: 'teacher.reports',
                component: () => import('@/teacher/pages/Reports/Reports.vue'),
                props: true,
            },
            {
                path: 'settings',
                name: 'teacher.user-profile',
                component: () =>
                    import('@/flows/User/ProfileView/UserProfileView.vue'),
                props: true,
            },
            {
                path: 'premium',
                name: 'teacher.premium',
                component: () =>
                    import(
                        '@/teacher/flows/TeacherPremium/TeacherPremiumPage.vue'
                    ),
                props: true,
            },
            {
                path: 'reports/:gameMode/:gameCode',
                name: 'teacher.game-report',
                component: () =>
                    import('@/teacher/pages/Reports/GameReport.vue'),
                props: true,
                meta: {
                    isReportOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/self-paced-lobby',
                name: 'host.self-paced-lobby',
                component: () =>
                    import(
                        '@/flows/SelfPacedGame/SelfPacedLobbyView/SelfPacedLobbyView.vue'
                    ),
                props: true,
                meta: {
                    isGameOwner: true,
                },
            },
            {
                path: 'ui-demo',
                name: 'teacher.ui-demo',
                component: () => import('@/teacher/_debug/TeacherUiDemo.vue'),
                props: true,
                beforeEnter: (to, from, next) => {
                    let isDev = localStorage.getItem('dev-tools');
                    if (!isDev) {
                        return next({ name: 'host' });
                    }
                    next();
                },
            },
            {
                path: 'monthly-competition',
                name: 'monthly-competition',
                component: () =>
                    import(
                        '@/teacher/pages/MonthlyCompetition/MonthlyCompetitionLandingPage.vue'
                    ),
                props: true,
            },
            {
                path: 'monthly-competition-registration',
                name: 'monthly-competition.registration',
                component: () =>
                    import(
                        '@/teacher/pages/MonthlyCompetition/MonthlyCompetitionRegistration.vue'
                    ),
                props: true,
            },
            {
                path: 'monthly-competition-leaderboard',
                name: 'monthly-competition.leaderboard',
                component: () =>
                    import(
                        '@/teacher/pages/MonthlyCompetition/MonthlyCompetitionLeaderboard.vue'
                    ),
                props: true,
            },
            {
                path: 'monthly-competition-results',
                name: 'monthly-competition.results',
                component: () =>
                    import(
                        '@/teacher/pages/MonthlyCompetition/MonthlyCompetitionResults.vue'
                    ),
                props: true,
            },
            {
                path: 'cert-individual/:classCode',
                name: 'certificate.individual',
                component: () =>
                    import(
                        '@/teacher/pages/MonthlyCompetition/MonthlyCompetitionIndividualCertificate.vue'
                    ),
                props: true,
                meta: {
                    authenticate: true,
                },
            },
            {
                path: 'cert-class/:classCode',
                name: 'certificate.class',
                component: () =>
                    import(
                        '@/teacher/pages/MonthlyCompetition/MonthlyCompetitionClassCertificate.vue'
                    ),
                props: true,
                meta: {
                    authenticate: true,
                },
            },
            {
                path: 'configure/:gameType',
                name: 'create-game.configure',
                component: () =>
                    import(
                        '@/teacher/pages/TopicConfigurationView/TeacherTopicConfigurationView.vue'
                    ),
                props: true,
            },
            {
                path: 'academy/overview',
                name: 'academy.overview',
                component: () =>
                    import('@/teacher/pages/Academy/AcademyOverviewPage.vue'),
                props: true,
            },
            {
                path: 'academy/weekly-goal',
                name: 'academy.weekly-goal',
                component: () =>
                    import('@/teacher/pages/Academy/AcademyWeeklyGoal.vue'),
                props: true,
            },
            {
                path: 'academy/assignments',
                name: 'academy.assignments',
                component: () =>
                    import('@/teacher/pages/Academy/AcademyAssignments.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/host',
        component: () =>
            import('@/flows/TimeBasedLiveGame/Host/HostContainer.vue'),
        meta: {
            authenticate: true,
        },
        children: [
            {
                path: '',
                name: 'old.host',
                redirect: { name: 'teacher.school' },
            },
            {
                path: 'dashboard',
                name: 'host.dashboard',
                redirect: { name: 'teacher.tbl.setup' },
            },
            {
                path: 'configure/:gameType',
                name: 'host.create-game.configure',
                redirect: { name: 'create-game.configure' },
                props: true,
            },
            {
                path: 'choose-game-mode',
                name: 'host.create-game.game-mode',
                component: () =>
                    import(
                        '@/teacher/flows/TeacherDashboard/DashboardView/components/GameModeSelectionView/TeacherGameModeSelectionView.vue'
                    ),
                props: true,
            },
            {
                path: 'create-game',
                name: 'host.create-game',
                component: () =>
                    import(
                        '@/teacher/flows/TeacherDashboard/DashboardView/CreateGameView/CreateGameView.vue'
                    ),
                props: true,
            },
            {
                path: ':gameCode/lobby',
                name: 'host.lobby',
                component: () =>
                    import(
                        '@/flows/TimeBasedLiveGame/Host/LobbyView/LobbyView.vue'
                    ),
                props: true,
                meta: {
                    isGameOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/lobby/self-play',
                name: 'host.lobby-self-play',
                component: () =>
                    import(
                        '@/flows/TimeBasedLiveGame/Host/LobbyView/SelfPlayLobbyView.vue'
                    ),
                props: true,
                meta: {
                    isGameOwner: true,
                },
            },
            {
                path: ':gameCode/preview',
                name: 'host.preview',
                component: () =>
                    import(
                        '@/flows/TimeBasedLiveGame/Host/PreGameView/PreGameView.vue'
                    ),
                props: true,
                meta: {
                    isGameOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/play',
                name: 'host.play',
                component: () =>
                    import(
                        '@/flows/TimeBasedLiveGame/Host/GameView/GameView.vue'
                    ),
                props: true,
                meta: {
                    isGameOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/results',
                name: 'host.results',
                component: () =>
                    import(
                        '@/flows/TimeBasedLiveGame/Host/GameEndedView/GameEndedView.vue'
                    ),
                props: true,
                meta: {
                    isGameOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/certificates',
                name: 'host.certificates',
                component: () =>
                    import(
                        '@/flows/TimeBasedLiveGame/Host/GameEndedView/components/Podium/CertificatePdf.vue'
                    ),
                props: true,
            },
            {
                path: ':gameCode/analytics',
                name: 'host.live-analytics',
                component: () =>
                    import(
                        '@/flows/GameReports/TblGameReport/TblGameReport.vue'
                    ),
                props: true,
                meta: {
                    isReportOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/self-paced-analytics',
                name: 'host.self-paced-analytics',
                component: () =>
                    import(
                        '@/flows/GameReports/SelfPacedGameReportView/SelfPacedGameReportView.vue'
                    ),
                props: true,
                meta: {
                    requireGameReport: true,
                },
            },
            {
                // Force the route to be /reports, not /host/reports.
                path: '/reports',
                name: 'host.reports',
                redirect: { name: 'teacher.reports' },
                props: true,
            },
            {
                path: '/reports/sample',
                name: 'host.reports.sample',
                component: () =>
                    import(
                        '@/flows/GameReports/ReportsView/ReportsSampleView.vue'
                    ),
                props: true,
            },
            {
                path: ':gameCode/lobby/spg',
                name: 'spg-lobby',
                component: () =>
                    import(
                        '@/teacher/pages/TopicConfigurationView/MobileFlow/StepInvite.vue'
                    ),
                props: true,
            },
            {
                path: ':gameCode/lobby/live',
                name: 'live-lobby',
                component: () =>
                    import(
                        '@/teacher/pages/TopicConfigurationView/MobileFlow/StepInviteLive.vue'
                    ),
                props: true,
            },
        ],
    },
    {
        path: '/teacher',
        name: 'teacher',
        component: () =>
            import(
                '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/TeacherOnboardingContainer.vue'
            ),
        meta: {
            authenticate: true,
            requireTeacherRole: true,
        },
        children: [
            {
                path: 'profile-setup',
                name: 'teacher.profile-setup',
                component: () =>
                    import(
                        '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/SetupViews/TeacherProfileSetupView.vue'
                    ),
                props: true,
            },
            {
                path: 'demo/join',
                name: 'student.demo.join',
                component: () =>
                    import(
                        '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/TeacherOnboardingStudentJoinDemoView.vue'
                    ),
                props: true,
            },
            {
                path: 'live-game',
                name: 'student-demo.live-game',
                component: () =>
                    import(
                        '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LiveGame/LiveGameContainer.vue'
                    ),
                children: [
                    {
                        path: 'avatar-selection/:code',
                        name: 'student-demo.livegame.avatar-selection',
                        component: () =>
                            import(
                                '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LiveGame/AvatarSelectionView.vue'
                            ),
                        props: true,
                    },
                    {
                        path: 'preview/:code',
                        name: 'student-demo.livegame.preview',
                        component: () =>
                            import(
                                '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LiveGame/PreGameView/PreGameView.vue'
                            ),
                        props: true,
                    },
                    {
                        path: 'results/:code',
                        name: 'student-demo.livegame.results',
                        component: () =>
                            import(
                                '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LiveGame/GameEndedView.vue'
                            ),
                        props: true,
                    },
                    {
                        path: 'play/:code',
                        name: 'student-demo.livegame.gameview',
                        component: () =>
                            import(
                                '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LiveGame/GameView/GameView.vue'
                            ),
                        props: true,
                    },
                ],
            },
            {
                path: 'demo/:gameCode/lobby',
                name: 'teacher.demo.lobby',
                component: () =>
                    import(
                        '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LobbyView.vue'
                    ),
                props: true,
            },
            {
                path: 'demo/:gameCode/play',
                name: 'teacher.demo.play',
                component: () =>
                    import(
                        '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/GameView.vue'
                    ),
                props: true,
            },
            {
                path: 'demo/:gameCode/results',
                name: 'teacher.demo.results',
                component: () =>
                    import(
                        '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/GameEndedView.vue'
                    ),
                props: true,
            },
            {
                path: 'demo/:gameCode/report',
                name: 'teacher.demo.report',
                component: () =>
                    import(
                        '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/ReportView.vue'
                    ),
                props: true,
            },
        ],
    },
    {
        path: '/teacher-register',
        name: 'teacher-register',
        component: () =>
            import(
                '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/RegisterViews/TeacherRegisterViewV2.vue'
            ),
        props: true,
    },
    {
        path: '/clever-library-register',
        name: 'clever-library-register',
        component: () =>
            import(
                '@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/CleverLibraryViews/CleverLibraryView.vue'
            ),
        props: true,
    },
    {
        path: '/classes',
        component: () =>
            import('@/teacher/flows/ClassLists/ClassListContainer.vue'),
        meta: {
            authenticate: true,
            requireTeacherRole: true,
        },
        children: [
            {
                path: '',
                name: 'classes.class-overview',
                component: () =>
                    import(
                        '@/teacher/flows/ClassLists/ClassOverview/ClassOverview.vue'
                    ),
                props: true,
            },
            {
                path: 'create/class',
                name: 'classes.create.class',
                component: () =>
                    import(
                        '@/teacher/flows/ClassLists/CreateView/CreateClass.vue'
                    ),
                props: true,
            },
            {
                path: ':classCode/studai',
                name: 'classes.class-student-access-info',
                component: () =>
                    import(
                        '@/teacher/flows/ClassLists/AccessInfoView/ClassroomStudentAccessInformation.vue'
                    ),
                props: true,
            },
            {
                path: ':classCode/individual-report/:studentId',
                name: 'classes.individual-report',
                component: () =>
                    import(
                        '@/teacher/flows/ClassLists/IndividualProgressView/IndividualProgressView.vue'
                    ),
                props: true,
            },
        ],
    },
    {
        path: '/independent-work',
        name: 'independent-work',
        component: () =>
            import(
                '@/teacher/flows/TeacherSoloTrack/SoloModePage/SoloModePage.vue'
            ),
        props: true,
    },
    {
        path: '/home-play',
        name: 'home-play',
        component: () => import('@/teacher/flows/HomePlay/HomePlay.vue'),
        props: true,
    },
    {
        path: '/class-pdf',
        name: 'class-pdf',
        component: () =>
            import('@/teacher/flows/HomePlay/StudentsInClassPDF.vue'),
        props: true,
    },
    {
        path: '/myclass/:classCode',
        name: 'invitation-page',
        component: () => import('@/core/pages/InvitationPage.vue'),
        props: true,
    },
    {
        path: '/premium',
        name: 'premium',
        component: () =>
            import('@/teacher/flows/TeacherPremium/TeacherPremiumPage.vue'),
        props: true,
    },
    {
        path: '/buy-teacher-premium',
        name: 'buy-teacher-premium',
        component: () =>
            import('@/teacher/flows/TeacherPremium/BuyTeacherPremium.vue'),
        props: true,
    },
    {
        /**
         * Set premium to current logged in teacher.
         */
        path: '/unlock/premium',
        component: () => import('@/core/helpers/TeacherPremiumUnlocker.vue'),
        props: true,
        meta: {
            authenticate: true,
        },
    },
    {
        path: '/student-demo',
        name: 'student-demo',
        props: true,
        component: () =>
            import('@/teacher/flows/TeacherSoloTrack/StudentDemo.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/student/:studentId/report',
        name: 'student.report',
        props: true,
        component: () =>
            import(
                '@/teacher/flows/ClassLists/IndividualProgressView/IndividualProgressView.vue'
            ),
        meta: {
            authenticate: true,
        },
    },
];
