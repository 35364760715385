import store from '@/store';

export const MathRunnerRoutes = [
    {
        name: 'math-runner.games-list',
        path: '/math-runner/games-list',
        component: () => import('@/flows/MathRunner/Revamp/GameList.vue'),
        props: true,
    },
    {
        name: 'math-runner.lobby',
        path: '/math-runner/:gameCode/lobby',
        component: () => import('@/flows/MathRunner/Revamp/LobbyView.vue'),
        props: true,
    },
    {
        name: 'math-runner.game-pregame',
        path: '/math-runner/:gameCode/pre-game',
        component: () => import('@/flows/MathRunner/Game/PreGameView.vue'),
        props: true,
    },
    {
        name: 'math-runner.game',
        path: '/math-runner/:gameCode/game',
        component: () => import('@/flows/MathRunner/Game/GameView.vue'),
        props: true,
    },
    {
        name: 'math-runner.game-end',
        path: '/math-runner/:gameCode/results',
        component: () =>
            import('@/flows/MathRunner/GameEndView/GameEndView.vue'),
        props: true,
    },
];
