import { $dt, definePreset, palette } from '@primevue/themes';
import Lara from '@primevue/themes/lara';

// --Identity-Accent-Live-games
const primaryColorPalette = palette('#ffc75a');
// --UI-Deep-Purple
export const secondaryColorPalette = palette('#572598');
// --Identity-Accent-color-3;
// todo: this is actually teal palette; consider preserving green and changing success button only
const green99ColorPalette = palette('#14c1bf');
// --Identity-Accent-Primary-CTA
const orange99ColorPalette = palette('#f08a4f');

export default definePreset(Lara, {
    primitive: {
        green: green99ColorPalette,
        orange: orange99ColorPalette,
    },
    components: {
        listbox: {
            checkmarkColor: 'var(--p-surface-700)',
        },
        tabs: {
            tablistBackground: 'transparent',
        },
        panelmenu: {
            colorScheme: {
                dark: {
                    panel: {
                        background: 'transparent',
                        borderWidth: 0,
                        firstBorderWidth: 0,
                        lastBorderWidth: 0,
                    },
                    item: {
                        focusBackground: 'var(--p-purple-700)',
                    },
                },
            },
        },
        button: {
            colorScheme: {
                dark: {
                    primary: {
                        background: 'var(--Identity-Accent-Live-games)',
                    },
                    secondary: {
                        background: 'var(--UI-Deep-Purple)',
                        borderColor: 'var(--UI-Deep-Purple)',
                        color: 'white',
                    },
                    // SPG
                    success: {
                        background: 'var(--Identity-Accent-color-3)',
                        hoverBackground: '{green.600}',
                        activeBackground: '{green.700}',
                        borderColor: 'var(--Identity-Accent-color-3)',
                        hoverBorderColor: '{green.600}',
                        activeBorderColor: '{green.700}',
                        color: 'white',
                        hoverColor: 'white',
                        activeColor: 'white',
                    },
                    // TBL
                    warn: {
                        background: 'var(--Identity-Accent-Primary-CTA)',
                        hoverBackground: '{orange.600}',
                        activeBackground: '{orange.700}',
                        borderColor: 'var(--Identity-Accent-Primary-CTA)',
                        hoverBorderColor: '{orange.600}',
                        activeBorderColor: '{orange.700}',
                        color: 'white',
                        hoverColor: 'white',
                        activeColor: 'white',
                    },
                },
            },
        },
    },
    directives: {
        tooltip: {
            colorScheme: {
                dark: {
                    background: 'var(--p-surface-900)',
                },
            },
        },
    },
    semantic: {
        primary: primaryColorPalette,
        // primary: {
        //     50: '{violet.50}',
        //     100: '{violet.100}',
        //     200: '{violet.200}',
        //     300: '{violet.300}',
        //     400: '{violet.400}',
        //     500: '{violet.500}',
        //     600: '{violet.600}',
        //     700: '{violet.700}',
        //     800: '{violet.800}',
        //     900: '{violet.900}',
        //     950: '{violet.950}',
        // },
        colorScheme: {
            light: {
                surface: {
                    0: '#ffffff',
                    50: '{zinc.50}',
                    100: '{zinc.100}',
                    200: '{zinc.200}',
                    300: '{zinc.300}',
                    400: '{zinc.400}',
                    500: '{zinc.500}',
                    600: '{zinc.600}',
                    700: '{zinc.700}',
                    800: '{zinc.800}',
                    900: '{zinc.900}',
                    950: '{zinc.950}',
                },
            },
            dark: {
                surface: {
                    0: '#ffffff',
                    50: '{violet.50}',
                    100: '{violet.100}',
                    200: '{violet.200}',
                    300: '{violet.300}',
                    400: '{violet.400}',
                    500: '{violet.500}',
                    600: '{violet.600}',
                    700: '{violet.700}',
                    800: '{violet.800}',
                    900: '{violet.900}',
                    950: '{violet.950}',
                },
                formField: {
                    background: 'var(--UI-Bg-Purple)',
                    borderColor: 'var(--p-form-field-background)',
                },
                list: {
                    optionBorderRadius: '4px',
                    optionFocusBackground: 'var(--p-purple-700)',
                    optionSelectedBackground: 'white',
                    optionSelectedColor: 'var(--p-surface-700)',
                    checkmarkColor: 'var(--p-surface-700)',
                    optionSelectedFocusBackground: 'white',
                    optionSelectedFocusColor: 'var(--p-surface-700)',
                },
            },
        },
    },
});
